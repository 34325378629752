<template>
  <p class="h2">
    {{ item.name }}
  </p>
  <form @submit.prevent="onSubmit">
    <div class="mb-3">
      <label for="name" class="form-label">
        <template v-if="isNew"> New Playlist </template>
        Title
      </label>
      <input id="name" v-model="item.name" type="text" class="form-control">
    </div>
    <div class="mb-3">
      <label for="exampleInputPassword1" class="form-label">Owner</label>
      <select v-model="item.user" class="form-select">
        <option v-for="user in users" :key="user._id" :value="user._id">
          {{ user.name }}
        </option>
      </select>
      <!--
      <input id="user" v-model="item.owner" type="text" class="form-control">
      -->
    </div>

    <button type="submit" class="btn btn-primary">
      Submit
    </button>
  </form>
</template>
<script>
import axios from "axios";
import router from "@/router";

export default {
  name: "PlalistEdit",
  components: {},
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      item: {},
      users: {},
      isNew: false,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    onSubmit: async function () {
      if (this.id == -1) {
        await axios.post(`/api/rest/dao/playlist/`, {
          name: this.item.name,
          user: this.item.user,
        });
        return router.back();
      }

      await axios.put(`/api/rest/dao/playlist/${this.id}`, {
        name: this.item.name,
        user: this.item.user,
      });
      return router.back();
    },
    load: async function () {
      try {
        this.isNew = this.id == -1;

        const { data: users } = await axios.get(`/api/rest/dao/user`);
        this.users = users;

        const { data: item } = await axios.get(
          `/api/rest/dao/playlist/${this.id}/`
        );
        this.item = item;
      } catch (err) {
        this.errors = err.response.data;
      }
    },
  },
};
</script>
